/*
* ===================================================
*    Custom Bootstrapious Utils
* ===================================================
*/

/*
   Text utils
   ------------------------
*/

.text-uppercase {
	text-transform: uppercase;
	letter-spacing: 0.1em;
}

.text-sm {
	font-size: $font-size-sm;
}

.text-base {
	font-size: $font-size-base;
}

.text-lg {
	font-size: $font-size-lg;
}

.text-shadow {
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.text-shadow-primary {
	text-shadow: 2px 2px 2px rgba($primary, 0.1);
}

.text-decoration-underline {
	text-decoration: underline;
}

.text-inherit {
	color: inherit;
}

/*
    Rounded corners - lg, sm 
   ------------------------
*/

.rounded-lg {
	border-radius: $border-radius-lg;
}

.rounded-sm {
	border-radius: $border-radius-sm;
}

.rounded-xl {
	border-radius: 10rem;
}

/*
    Responsive borders - border-[breakpoint]
   ------------------------
*/
@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.border#{$infix} {
			border: $border-width solid $border-color !important;
		}
	}
}

/*
    Opacity helpers - .opacity-[1-9]   
    ------------------------
*/

$opacity: 1 2 3 4 5 6 7 8 9 10;

@each $current-opacity in $opacity {
	.opacity-#{$current-opacity} {
		opacity: #{$current-opacity/10};
	}
}

/*
    Z-index helpers - .z-index-[10-50]   
    ------------------------
*/

$z-index: 10 20 30 40 50;

@each $current-z-index in $z-index {
	.z-index-#{$current-z-index} {
		z-index: #{$current-z-index};
	}
}

/*
     Letter spacing helpers - .letter-spacing-[0-5]
    ------------------------
*/

.letter-spacing-0 {
	letter-spacing: 0 !important;
}

$spacing: 0.1em 0.2em 0.3em 0.4em 0.5em;

@each $current-spacing in $spacing {
	$i: index($spacing, $current-spacing);

	.letter-spacing-#{$i} {
		letter-spacing: #{$current-spacing};
	}
}

/*
     Colours and backgrounds
    ------------------------
*/

@each $color, $value in $theme-colors {
	.text-hover-#{$color} {
		transition: $transition-base;

		&:focus,
		&:hover {
			color: $value !important;
		}
	}

	a.text-hover-#{$color} {
		transition: $transition-base;

		&:focus,
		&:hover {
			color: $value !important;
		}
	}

	.bg-#{$color}-light {
		background-color: theme-color-level($color, -11);
	}

	.border-#{$color} {
		border-color: $value !important;
	}
}

@each $color, $value in $grays {
	.bg-gray-#{$color} {
		background: $value !important;
	}

	.text-gray-#{$color} {
		color: $value;
	}
}

@each $color, $value in $theme-colors {
	.badge-#{$color}-light {
		color: $value;
		background-color: theme-color-level($color, -11);
	}
}

/*
     Transparent overlays
    ------------------------
*/

.overlay-content {
	position: relative;
	z-index: 20;
}

@each $breakpoint in map-keys($grid-breakpoints) {
	$next: breakpoint-next($breakpoint, $grid-breakpoints);
	$infix: breakpoint-infix($next, $grid-breakpoints);

	.light-overlay#{$infix},
	.overlay-hover-light#{$infix} {
		@include media-breakpoint-up($next) {
			position: relative;

			&::after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				content: ' ';
				background: #fff;
			}
		}
	}

	.overlay-hover-light#{$infix} {
		@include media-breakpoint-up($next) {
			&::after {
				transition: $transition-fade;
				opacity: 0;
			}
		}
	}

	.light-overlay#{$infix},
	.overlay-hover-light#{$infix}:hover {
		@include media-breakpoint-up($next) {
			&::after {
				opacity: 0.4;
			}
		}
	}

	.light-overlay#{$infix}-0,
	.overlay-hover-light#{$infix}-0:hover {
		@include media-breakpoint-up($next) {
			&::after {
				display: none;
			}
		}
	}

	.dark-overlay#{$infix} {
		position: relative;

		@include media-breakpoint-up($next) {
			&::after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				content: ' ';
				opacity: 0.5;
				background: #000;
				z-index: 22;
			}
		}
	}

	.dark-overlay#{$infix}-0 {
		@include media-breakpoint-up($next) {
			&::after {
				display: none;
			}
		}
	}
}
/*
     Other
    ------------------------
*/
.text-small {
	font-size: 0.9rem !important;
}

.overflow-visible {
	overflow: visible !important;
}

.shadow-0 {
	box-shadow: none !important;
}
.shadow-xs {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.07) !important;
}

.bg-cover {
	background-size: cover !important;
}

.bg-center {
	background-position: center !important;
}

.bg-none {
	background: none !important;
}

.index-forward {
	position: relative;
	z-index: 99;
}

.reset-anchor {
	color: inherit;
	transition: all 0.3s;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}
.reset-anchor-inherit {
	color: inherit;

	&:hover,
	&:focus {
		color: inherit;
		text-decoration: none;
	}
}

.hover-transition {
	transition: all 0.3s;
	&:hover {
		transform: translateY(-3px);
	}
}
