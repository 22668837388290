/*
*
* ==========================================
* GENERAL
* ==========================================
*
*/

a {
	text-decoration: none;
}

.form-control {
	&::placeholder {
		font-size: 0.85rem;
		font-weight: $font-weight-normal;
	}
	&[type='file'] {
		border: none;
		background: $light;
	}
	&::file-selector-button {
		border-radius: 0.25rem;
		font-size: 0.9rem;
		box-shadow: 0 2px 2px rgba($black, 0.1);
	}
}
.form-check-label {
	font-size: 0.9rem;
	font-weight: $font-weight-bold;
}

section {
	position: relative;

	&.hero {
		@include media-breakpoint-up(lg) {
			height: 25rem;
		}
		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: #000;
			opacity: 0.4;
		}
	}

	&.hero-home {
		&::before {
			content: '';
			display: block;
			background: darken($gray-100, 1%);
			width: 50%;
			height: calc(100% + 12rem);
			position: absolute;
			top: 0;
			right: 0;
		}

		&::after {
			content: '';
			display: block;
			width: 160px;
			height: 160px;
			background: url(../img/pattern.svg);
			background-size: 10rem;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0.5;
		}

		& > .container {
			position: relative;
			&::before {
				content: '';
				display: block;
				width: 120px;
				height: 120px;
				background: url(../img/triangle.svg) no-repeat;
				background-size: 100%;
				position: absolute;
				top: 6rem;
				right: 0;
				opacity: 0.03;
				z-index: 1;
			}

			&::after {
				content: '';
				display: block;
				width: 60px;
				height: 60px;
				background: url(../img/circle.svg) no-repeat;
				background-size: 100%;
				position: absolute;
				left: 0;
				bottom: 1rem;
				opacity: 0.03;
				z-index: -1;
			}
		}
	}
}

.tooltip-inner {
	background-color: $primary;
	font-family: $font-family-sans-serif;
	padding: 0.2rem 0.5rem;
}
.tooltip.bs-tooltip-top .tooltip-arrow::before {
	border-top-color: $primary;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
	border-bottom-color: $primary;
}

.tooltip.bs-tooltip-start .tooltip-arrow::before {
	border-left-color: $primary;
}

.tooltip.bs-tooltip-end .tooltip-arrow::before {
	border-right-color: $primary;
}

$socialColors: (
	'facebook': #3b5999,
	'twitter': #55acee,
	'youtube': #e02827,
	'vimeo': #1ab7ea,
	'instagram': #e4405f,
	'pinterest': #cd0b21,
	'linkedin': #0077b5,
	'tumblr': #34465d,
	'whatsapp': #25d366,
);

.social-link {
	width: 36px;
	height: 36px;
	border-radius: $border-radius;
	background: $primary;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s;

	&:hover,
	&:focus {
		text-decoration: none;
		color: #fff;
	}
}

@each $color, $value in $socialColors {
	.#{$color} {
		background: $value;
		&:hover,
		&:focus {
			background: darken($value, 10%);
		}
	}
}

.rating {
	direction: rtl;
	color: #dadada;
	input {
		display: none;
	}

	label {
		cursor: pointer;
	}
}

.rating > input:checked + label,
.rating > input:checked ~ input + label {
	color: $warning;
}

/*
*
* ==========================================
* NAVBAR
* ==========================================
*
*/
.navbar {
	.dropdown-menu {
		@include media-breakpoint-up(lg) {
			display: block;
		}
		visibility: hidden;
		opacity: 0;
		transform: translateY(0.5rem);
		transition: 0.2s ease all;
	}
	.dropdown-menu.show {
		display: block;
		visibility: visible;
		opacity: 1;
		transform: translateY(0px);
		transition: 0.2s ease all;
	}
}

.dropdown-toggle {
	&::after {
		border: none;
		content: '\f078';
		font-family: 'Font Awesome\ 5 Free';
		font-weight: 900;
		font-size: 0.65rem;
		vertical-align: middle;
		padding-left: 0.1rem;
	}
}

.navbar {
	z-index: 999;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/*
*
* ==========================================
* CATEGORY BLOCK
* ==========================================
*
*/
.categories-item {
	position: relative;
	transition: all 0.2s;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		display: block;
		background: linear-gradient(45deg, $primary, adjust-hue($primary, 15%));
		transition: all 0.3s;
		border-radius: $border-radius;
		opacity: 0;
		z-index: 1;
	}

	.card-body {
		z-index: 2;
	}

	svg {
		color: $primary;
		transition: all 0.3s;
	}

	&:hover {
		color: #fff;

		svg {
			color: white;
		}

		&::before {
			opacity: 1;
		}

		.categories-item-number {
			color: white;
		}
	}

	.categories-item-number {
		color: $text-muted;
		transition: all 0.2s;
	}
}

/*
*
* ==========================================
* TOOLS STYLES
* ==========================================
*
*/
.essential-tool-img {
	width: 5rem;
	height: 5rem;
	background-size: 100% !important;
	background-position: center center !important;
	background-repeat: no-repeat !important;
}

.tool-thumb {
	position: absolute;
	top: 1rem;
	left: 1rem;
	z-index: 90;
	border: 2px solid white;
}

.tool-rating {
	position: absolute;
	bottom: 1rem;
	right: 1rem;
	font-size: 0.6rem;
	z-index: 99;
	padding: 0.35rem 0.7rem;
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 0.2rem;
}

.featured-badge {
	color: #fff;
	padding: 0.35rem 1rem;
	font-size: 0.6rem;
	position: absolute;
	bottom: 1rem;
	right: 7rem;
	z-index: 99;
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 0.2rem;
}
