/*
* ===================================================
*    Custom Orion Icons
* ===================================================
*/

.svg-icon {
    width: 32px;
    height: 32px;
    vertical-align: text-bottom;
    stroke: currentColor;
    stroke-width: 3;

    --layer1: currentColor;
    --layer2: currentColor;
    --layer3: currentColor;

    &.svg-icon-light {
        stroke-width: 2;
    }

    &.svg-icon-heavy {
        stroke-width: 4;
    }

}

.svg-icon-big {
    width: 48px;
    height: 48px;
}

.svg-icon-sm {
    width: 26px;
    height: 26px;
}
