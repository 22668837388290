.owl-thumb-item {
	cursor: pointer;
}
/*
*
* ==========================================
* CHOICES
* ==========================================
*
*/
.choices__list--single {
	padding: 0 !important;
	font-size: 0.9rem !important;
}

.choices__list--dropdown {
	border-radius: 0 !important;
	z-index: 999 !important;
	border-color: $input-border-color !important;
}

.choices {
	min-width: 12rem;
}

.choices__inner {
	min-height: 10px !important;
}

.choices__list--dropdown .choices__item {
	padding-right: 10px !important;
}

.choices__list--single .choices__item[data-id='1'] {
	color: $input-placeholder-color !important;
}

.choices__placeholder {
	opacity: 1 !important;
}

/*
*
* ==========================================
* SWIPER THUMBS
* ==========================================
*
*/
.swiper-slide {
	cursor: pointer;
	background: $black;

	img {
		transition: all 0.3s;
	}

	&.swiper-slide-thumb-active img {
		opacity: 0.7;
	}
}
